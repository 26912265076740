<template>
  <teleport v-if="movie && movie.product && movie.product.directors && movie.product.dates && movie.product.review.authors && movie.product.review && movie.product.review.review__rating && movie.product.poster && movie.product.translations" to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "https://schema.org/",
        "@type": "Review",
        "itemReviewed": {
          "@type": "Movie",
          "image": "{{movie.product.poster[0].file}}",
          "name": "{{movie.product.translations[0].title}}",
          "dateCreated": "{{movie.product.dates[0].date}}",
          "director": "{{movie.product.directors.map(entry => (entry.forname + ' ' + entry.surname)).join(', ')}}"
        },
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "{{movie.product.review.review__rating}}"
        },
        "author": {
          "@type": "Person",
          "name": "{{movie.product.review.authors[0].first_name}} {{movie.product.review.authors[0].last_name}}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Kino-Total.net"
        }
      }
    </component>
    <link v-if="movie && movie.product" :href="('https://kino-total.net/' + movie.product.slug + '/')" rel="canonical">
  </teleport>
  <div class="product-detail">
    <div class="teaser">
      <div v-if="e404" class="e404">
        <strong>404 Muhaahaha</strong><br/>💀<br/>
        <router-link :to="{name: 'Homepage'}">Zurück zur Homepage</router-link>
      </div>
      <div v-if="movie && movie.product && movie.product.mainview" :style="imageStyleMovie">
        <div class="teaser-shadow">
          <div class="container-fluid">
            <div class="teaser-box">
              <h1 v-if="movie.product.translations">
                {{movie.product.translations[0].title}}
              </h1>
              <p class="genres" v-if="movie.product.dates">
                <span v-if="movie.product.dates[0].type == 'series'">
                  <router-link :to="{name: 'Series'}" class="tag">Serienkritik</router-link>
                </span>
                <span v-else-if="movie.product.review && movie.product.review.style && movie.product.review.review__rating" class="genres">
                  <router-link :to="{name: 'Movies'}" class="tag">Filmkritik</router-link>
                </span>
                <span v-else class="genres">
                  <router-link :to="{name: 'Movies'}" class="tag">Synopsis</router-link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!e404 && movie && movie.product && movie.product.translation" class="teaser-shadow teaser-block">
        <div class="container-fluid">
          <div class="teaser-box">
            <h1>
              {{movie.product.translations[0].title}}
            </h1>
            <p v-if="movie && movie.product && movie.product.translations && movie.product.translations[0].description && movie.product.review && movie.product.review.review__headline">{{movie.product.translations[0].description}}</p>
            <p v-else-if="movie && movie.product && movie.product.year">{{movie.product.year}}</p>
          </div>
        </div>
      </div>
      <div v-else-if="movie.product"></div>
      <div v-else class="loader">Kritik zum Film wird geladen...</div>
    </div>
    <div class="container-fluid">
      <div class="review">

        <div class="review-main" v-if="movie.product">

          <div v-if="movie.product.review && movie.product.review.style && movie.product.review.review__rating" class="stars" :style="movie.product.review.style">
            <span> <b>{{ movie.product.review.review__rating }}</b>/5</span>
          </div>

          <h2 v-if="movie.product.review && movie.product.review.review__headline">
            {{movie.product.review.review__headline}}
          </h2>

          <div v-if="movie.product.review && movie.product.review.authors" class="avatars">
            <span v-for="entry in movie.product.review.authors" :key="entry.username">
              <router-link  :to="{name: 'Author', params: {username: entry.username}}">
                <img :src="entry.gravatar" alt="avatar" />
              </router-link>
              <router-link  :to="{name: 'Author', params: {username: entry.username}}">
                {{entry.first_name}} {{entry.last_name}}
              </router-link>
              <i>Autor</i>
            </span>
            <div class="clear"></div>
          </div>

          <p v-if="movie.product.review && movie.product.review.review__intro"
             v-html="movie.product.review.review__intro" class="text" />
          <p class="text" v-else-if="movie.product.translations && movie.product.translations[0].description">
            {{movie.product.translations[0].description}}
          </p>
          <p class="text" v-if="movie.product.review && movie.product.review.review__text"
             v-html="movie.product.review.review__text" />
          <p class="text"><strong v-if="movie.product.review && movie.product.review.review__conclusion"
             v-html="movie.product.review.review__conclusion" /></p>

          <div v-if="movie.product.videos" class="video">
            <iframe data-category="ads" :src="movie.product.videos[0].url" :title="movie.product.translations[0].title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>

        </div>

        <aside class="review-aside" v-if="movie.product">
          <img v-if="movie.product.poster" class="poster" :src="movie.product.poster[0].file" :alt="movie.product.translations[0].title" />

          <div class="social" v-if="(social_x || social_whatsapp || social_facebook)">
            <a class="twitter" v-if="social_x"
               title="Bei X empfehlen"
               :href="social_x"
               target="blank" rel="nofollow">
              X
            </a>
            <a class="facebook" v-if="social_facebook"
               title="Bei Facebook empfehlen"
               :href="social_facebook"
               target="blank" rel="nofollow">
              Facebook
            </a>
            <a class="whatsapp" v-if="social_whatsapp"
               title="Über Whatsapp empfehlen"
               :href="social_whatsapp"
               target="blank" rel="nofollow">
              WhatsApp
            </a>
          </div>

          <div v-if="movie.product.genres" class="genres">
            <span v-for="entry in movie.product.genres" :key="entry.slug">
              <router-link :to="{name: 'Genres', params: {slug:entry.slug}}" class="tag">{{entry.title}}</router-link>
            </span>
          </div>

          <p v-if="movie.product.dates">
            <!-- For each dates entry, display the date and type -->
            <span v-for="entry in movie.product.dates" :key="entry">
              <span v-if="entry.type == 'release' && (new Date(entry.date) < new Date('2900-01-01'))">
                <b>Kinostart<span v-if="entry.country == 'USA'"> (USA)</span>:</b>
                {{ date_format( entry.date )}}<br>
              </span>
              <span v-else-if="entry.type == 'streaming' && (new Date(entry.date) < new Date('2900-01-01'))">
                <b>Streamingstart:</b>
                {{ date_format( entry.date )}}<br>
              </span>
            </span>
          </p>

          <p v-if="movie.product.fsk">
            <b>FSK:</b> ab {{movie.product.fsk}} Jahren
          </p>

          <p v-if="movie.product.duration">
            <b>Länge:</b> {{movie.product.duration}} Minuten
          </p>

          <p v-if="movie.product.countries && movie.product.year">
            <b>Land / Jahr:</b> {{movie.product.countries.map(entry => (entry.title)).join(', ')}}
            ({{movie.product.year}})
          </p>

          <p v-if="movie.product.budget">
            <b>Budget:</b> {{ number_format(movie.product.budget) }}
            <span v-if="movie.product.budget_currency"> {{movie.product.budget_currency}}</span>
            <span v-else> US-Dollar</span>
          </p>

          <p v-if="movie.product.actors">
            <b>Darsteller:</b> {{movie.product.actors.map(entry => (entry.forname + " " + entry.surname + (entry.role ? " (" + entry.role.trim() + ")" : ""))).join(', ')}}
          </p>

          <p v-if="movie.product.directors">
            <b>Regie:</b> {{movie.product.directors.map(entry => (entry.forname + " " + entry.surname)).join(', ')}}
          </p>

          <p v-if="movie.product.writers">
            <b>Drehbuch:</b> {{movie.product.writers.map(entry => (entry.forname + " " + entry.surname)).join(', ')}}
          </p>

          <p v-if="movie.product.metascore ">
            <b>Metascore:</b> {{ movie.product.metascore }}
          </p>

          <p v-if="movie.product.imdb ">
            <b>IMDb:</b> {{ movie.product.imdb }}
          </p>

          <p v-if="movie.product.review && movie.product.review.review__publication_date && movie.product.review.review__rating && movie.product.review.review__rating > 0">
            Kritik veröffentlicht am {{ date_format( movie.product.review.review__publication_date )}}
          </p>

          <div v-if="positions" class="rectangle">
            <div>Werbung</div>
            <span v-for="entry in positions" :key="entry.id">
              <a :href="entry.url" target="_blank" rel=”nofollow”>
                <img v-if="entry.image" v-lazy="{ src: entry.image }" :alt="entry.name" />
              </a>
            </span>
          </div>

        </aside>

        <div class="clear"></div>

      </div>

      <div style="margin-top:20px; text-align: center; font-size: 10px;">
        Werbung<br>
        <a href="https://www.herder.de/lesen-hoeren-schenken/shop/p5/86558-die-erfindung-von-mittelerde-gebundene-ausgabe/?utm_source=kino-total.net&utm_medium=display&utm_content=banner&utm_campaign=4260-7" target="_blank"><img src="https://kino-total.net/img/4260_Garth_Erfindung_von_Mittelerde_AZ Kino-total_798x90px_Mit_logo.jpg" style="max-width:798px; width:100%; height: auto;"></a>
      </div>

      <div class="header" v-if="movie.product && movie.product.news">
        <h2>
          Interessante Nachrichten
        </h2>
      </div>
      <div class="news" v-if="movie.product && movie.product.news">
        <div v-for="news_item in movie.product.news" :key="news_item.slug" class="entry">

            <router-link :to="{name: 'News', params: {slug: news_item.slug}}">
              <img class="listview" v-lazy="{ src: news_item.image }" :alt="news_item.title" />
            </router-link>
            <h3>
              <router-link :to="{name: 'News', params: {slug: news_item.slug}}">
                {{ news_item.title }}
              </router-link>
            </h3>
            <p><span class="tag">{{ date_format(news_item.publication_date) }}</span></p>
            <p class="hide-xs">{{ news_item.description }}</p>
        </div>
        <div class="clear"></div>
      </div>

      <div class="pagination" v-if="movie.product && movie.product.news">
        <div class="pagination-right">
          <router-link class="button" :to="{name: 'Newslist'}">Weitere News</router-link>
        </div>
        <div class="clear"></div>
      </div>

      <div class="header" v-if="movie.product && movie.product.products">
        <h2>
          Empfehlungen der Redaktion
        </h2>
      </div>
      <div class="movies" v-if="movie.product && movie.product.products">
        <div v-for="entry in movie.product.products" :key="entry.slug" class="entry">

          <router-link :to="{name: 'Product', params: {slug: entry.slug}, force: true}">
            <img v-if="entry.image" class="poster" v-lazy="{ src: entry.image.file }" :alt="entry.title" />
            <div v-else class="no-image-poster"></div>
          </router-link>
          <h3>
            <router-link :to="{name: 'Product', params: {slug: entry.slug}, force: true}">{{ entry.title }}</router-link>
          </h3>
        </div>
      </div>
      <div class="pagination">
        <div class="pagination-right">
          <router-link class="button" to="/">Zur Startseite</router-link>
        </div>
        <div class="clear"></div>
      </div>

    </div>
    <img :src="movie.product.review.review__vgwort" v-if="movie.product && movie.product.review && movie.product.review.review__vgwort" width="1" height="1" alt="VG" class="vgw-marker-image" loading="eager" data-no-lazy="1" referrerpolicy="no-referrer-when-downgrade" style="display:none;">
  </div>
</template>
<script>
import { dater, numberr, markdown } from '@/modules/utilities.js'
export default {
  name: 'ProductView',
  data () {
    return {
      movie: '',
      url: '',
      social_x: '',
      social_facebook: '',
      social_whatsapp: '',
      positions: '',
      e404: false,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    number_format (data) {
      return numberr(data)
    },
    markdown_format (data) {
      return markdown(data)
    },
    fetchData (slug) {
      fetch('https://api.kino-total.net/products/' + slug + '/', {
        method: 'GET'
      })
        .then((response) => {
          // 404
          if (response.status === 404) {
            this.e404 = true
            return
          }
          response.json().then((data) => {
            if (data.product.images && data.product.review && data.product.review.review__text) {
              /* Include images in Text between paragraphs, one image per split */
              const images = data.product.images
              const imagesScenes = images.filter(image => image.scene === true)
              const text = data.product.review.review__text
              const paragraphs = (text.includes('[BILD]') ? text.split('[BILD]') : text.split('\n'))
              const paragraphsFiltered = []
              for (let i = 0; i < paragraphs.length; i++) {
                if (paragraphs[i].length > 0) {
                  paragraphsFiltered.push(paragraphs[i])
                }
              }
              let i = 0
              let j = 0
              for (const paragraph of paragraphsFiltered) {
                if (paragraph.length > 200) {
                  if (imagesScenes[i]) {
                    paragraphsFiltered[j] = paragraph + '\n' + '![' + (imagesScenes[i].credits ? imagesScenes[i].credits : '') + '](' + imagesScenes[i].file + ')'
                  }
                  i++
                }
                j++
              }
              data.product.review.review__text = paragraphsFiltered.join('\n\n')
            }
            if (data.product.review && data.product.review.review__text) {
              data.product.review.review__text = this.markdown_format(data.product.review.review__text)
              data.product.review.review__intro = this.markdown_format(data.product.review.review__intro)
              data.product.review.review__conclusion = this.markdown_format(data.product.review.review__conclusion)
            }
            // replace url prefix in movie.product.review.review__vgwort
            // http://vgXY.met.vgwort.de (XY = 01-99) ->
            // https://ssl-vg03.met.vgwort.de
            if (data.product.review && data.product.review.review__vgwort) {
              data.product.review.review__vgwort = data.product.review.review__vgwort.replace(/http:\/\/vg(\d{2}).met.vgwort.de/g, 'https://ssl-vg03.met.vgwort.de')
            }

            this.movie = data
            if (this.movie && this.movie.product.review && this.movie.product.review.review__headline) {
              document.querySelector('head meta[name="description"]').setAttribute('content', this.movie.product.review.review__headline)
            }
            if (this.movie && this.movie.product && this.movie.product.translations && this.movie.product.translations[0] && this.movie.product.translations[0].title) {
              if (this.movie.product.other_review) {
                let alternateLink = document.querySelector('head link[rel="alternate"]')
                if (!alternateLink) {
                  alternateLink = document.createElement('link')
                  alternateLink.setAttribute('rel', 'alternate')
                  document.head.appendChild(alternateLink)
                }
                alternateLink.setAttribute('href', 'https://total-cinema.com/' + this.movie.product.slug)
              }
              document.querySelector('head title').textContent = this.movie.product.translations[0].title + ' - Kritik - Kino-Total.net'
            }
            if (this.movie && this.movie.product.review && this.movie.product.review.review__headline && this.movie.product.translations[0].title) {
              this.url = 'https://kino-total.net/' + this.movie.product.slug
              this.social_x = 'https://X.com/intent/tweet?source=webclient&amp;text=Kritik%20zu%20' + encodeURIComponent(this.movie.product.translations[0].title) + ':%20' + encodeURIComponent(this.movie.product.review.review__headline) + '&amp;url=' + encodeURIComponent(this.url)
              this.social_facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url)
              this.social_whatsapp = 'whatsapp://send?text=Kritik%20zu%20' + encodeURIComponent(this.movie.product.translations[0].title) + ':%20' + encodeURIComponent(this.url)
            }
            if (!this.$CookieConsent.getUserPreferences().acceptedServices.ads.includes('youtube')) {
              this.movie.product.videos = null
            }
          })
        })
        .catch((err) => {
          this.e404 = true
          console.error(err)
        })
      fetch('https://api.kino-total.net/positions/?position=Product', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.positions = data.positions
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/' + slug)
    }
  },
  created () {
    this.fetchData(this.$route.params.slug)
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.movie.product = null
        this.fetchData(toParams.slug)
        window.scrollTo(0, 0)
      }
    )
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    imageStyleMovie () {
      if (this.windowWidth > 1100) {
        return {
          backgroundImage: `url(${this.movie.product.mainview[0].file})`,
          backgroundPosition: 'center top',
          backgroundSize: 'auto 100%',
          width: '100%',
          height: '440px',
          marginTop: '-440px'
        }
      } else if (this.movie.product.mainmobile[0].file) {
        return {
          backgroundImage: `url(${this.movie.product.mainmobile[0].file})`,
          backgroundPosition: 'center top',
          backgroundSize: 'auto 100%',
          width: '100%',
          height: '480px',
          marginTop: '-440px'
        }
      }
      return {
        backgroundImage: `url(${this.movie.product.mainmobile[0].file})`,
        backgroundPosition: 'center top',
        backgroundSize: 'auto 100%',
        width: '100%',
        height: '480px',
        marginTop: '-440px'
      }
    }
  }
}
</script>
<style>

.review {
  margin: 20px auto 0;
  text-align: left;
  width: 100%;
  max-width: 1000px;
  font-size: 1.1rem;
  min-height: 800px;
}
.review p {
  line-height: 1.5;
}

.review-main {
  float: left;
  width: 65%;
  padding: 0 20px;
}

.review-main .video {
  margin-top: 20px;
}

.review-main .text a {
  color: rgb(4, 88, 119);
  text-decoration: underline;
}

.review-aside {
  float: right;
  width: 35%;
  min-width: 350px;
  padding: 0 20px;
}

.review-aside .poster {
  margin-top: -80px;
  -webkit-box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.75);
}

.review-aside .genres {
  margin-top: 20px;
}

.teaser-box .genres {
  margin-top: 0;
}
.teaser-box .genres .tag {
  color: #333;
}

.review-aside .genres .tag {
  font-size: 1rem;
}

.review-aside p {
  font-size: 1rem;
  margin-top: 20px;
}

.review-main figure {
  margin: 20px 0 0 0;
  padding: 0;
  text-align: right;
  width: 100%;
}
.review-main figure img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: auto;
  aspect-ratio: 610 / 360;
}
.review-main figure figcaption {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: right;
  color: #494947;
}

@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .review  {
    max-width: 100%;
  }
  .review-main {
    max-width: 500px;
    width: 100%;
    padding-right: 0;
  }
  .review-aside {
    margin-right: -60px;
    margin-top: 90px;
    min-width: 300px;
    max-width: 320px;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 800px) {
  .review-main {
    width: 100%;
    float: none;
    padding-right: 20px;
  }
  .review-main .video iframe {
    max-height: 250px;
    height: 250px;
    margin-bottom: 100px;
  }
  .review-aside {
    width: 100%;
    float: none;
    margin-top: 100px;
    padding-right: 20px;
  }
  .review-aside img {
    width: 300px;
    text-align: center;
  }
  .review-main p {
    font-size: 1rem;
  }
}
</style>
